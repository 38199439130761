import {ISelect} from "@/components/Select/helper";


export interface IOrderItem {
    topPath: ITopPath;
    business: string;
    fee: IFee;
    status: string;
}

export interface IFee {
    fee: number;
    weight: number;
    count: number;
}

export interface ITopPath {
    from: string;
    to: string;
    topType: string;
}
// 订单状态
export const orderStatMap = {
    0: '全部',
    20: '国内待取',
    40: '国内在途',
    60: '国内签收',
    80: '海外发货',
    90: '延误',
    91: '丢货',
    92: '签收未上架',
    100: '完成',
    150: '已赔付',
    200: '作废'
}

export const payStatMap = {
    0:'全部',
    10:'未支付',
    20:'已支付',
    100:'已退款'
}


export const getStatMap = (params: any) => {
    return Object.keys(params).reduce((result:ISelect[],i:string,index: number) => {
        result[index]={
            label: params[i],
            value: i
        }
        return result
    },[])
}

// 颜色
export const colorMap = {
    1: '#FF5100',
    2: '#999999',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '#45b97c',
    97:'',
    98:'#999999',
    99:'#999999'
}
// 物流类型

export const logisticsMap = {
    1: '快递',
    2: '空派',
    3: '海运',
    4: '卡航',
    5: '铁路'
}

export const logisticsType = [
    {label: '快递', value: 1},
    {label: '空派', value: 2},
    {label: '海运', value: 3},
    {label: '卡航', value: 4},
    {label: '铁路', value: 5},
]

export const getOrderStat = (source: any,type: number) => {
    let total: number = 0;
    const types:ISelect[] = type === 1 && getStatMap(orderStatMap) || getStatMap(payStatMap)
    return types.reduce((result:ISelect[],i:ISelect,index: number) => {
        let a = source.find((sub: any) => sub[type === 1 && 'orderState' || 'paymentState'] == i.value);
        if(a){
            result[index] = {
                ...i,
                count:a.count
            }
            result[0] = {
                ...types[0],
                count: total += Number(a?.count ?? 0)
            }
        } else {
            result[index] = i
        }
        return result
    }, [])
}
// 根据code 获取省市区名称
// pid code | type 0 省市区  1市区 2 区
export const getCity = (params: { pid: string, type?: number, source: any }) => {
    const {pid = '', type = 0, source} = params
    return pid.split('/').reduce((result: string, item: string, index) => {
        if (index >= type) {
            result += source?.find?.((i: any) => i?.code === item)?.name ?? ''
        }
        return result
    }, '')
}